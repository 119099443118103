var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full flex flex-col",class:['R0', 'R1', 'R2', 'R3'].includes(_vm.userRole) || _vm.isMe
      ? 'cursor-pointer'
      : ''},[_c('div',{staticClass:"flex w-full mobile:mx-0 flex-row px-3 py-5 h-full duration-100 mobile:flex-col",class:[
      _vm.isMe
        ? 'cursor-pointer bg-green bg-opacity-5 hover:bg-opacity-25'
        : 'bg-white hover:bg-offwhite hover:bg-opacity-50'
    ],on:{"mouseover":function($event){_vm.isHovered = true},"mouseleave":function($event){_vm.isHovered = false}}},[_c('div',{staticClass:"mobile:w-full w-full flex items-center mobile:flex-col"},[_c('div',{staticClass:"w-3/12 mobile:w-full items-center flex flex-row mobile:mb-2"},[(_vm.imageLoaded)?_c('div',{staticClass:"mr-2"},[_c('span',{staticClass:"typcn typcn-user table-icon"})]):_vm._e(),_c('h4',{staticClass:"text-center flex items-center"},[_vm._v(" "+_vm._s(_vm.worker.profile_name)+" "),(_vm.isMe)?_c('span',{staticClass:"text-lightgrey leading-none ml-1"},[_vm._v("(mina)")]):_vm._e()])]),_c('div',{staticClass:"w-3/12 mobile:w-full flex items-center"},[_vm._m(0),_c('span',{staticClass:"pl-2"},[_vm._v(" "+_vm._s(_vm.worker.telephone_number ? _vm.worker.telephone_number : "-"))])]),_c('div',{staticClass:"w-3/12 mobile:hidden flex items-center"},[_vm._m(1),_c('span',{staticClass:"pl-2"},[_vm._v(_vm._s(_vm.worker.email))])]),_c('div',{staticClass:"w-3/12 mobile:hidden flex items-center"},[_vm._m(2),_c('div',{staticClass:"flex truncate"},[(
              ['R0', 'R1', 'R2', 'R3'].includes(_vm.userRole) ||
                _vm.worker.profile === _vm.$store.state.userData.user.id
            )?_c('span',{staticClass:"text-sm pl-2 truncate"},[_vm._v(" "+_vm._s(_vm.worker.location ? _vm.worker.location.long_address : "-"))]):_vm._e()])])])]),_c('div',{staticClass:"bg-offwhite h-1 rounded-full w-full"})])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex border-2 border-green rounded-full justify-center items-center h-7 w-7"},[_c('img',{staticClass:"h-4 filter-to-green",attrs:{"src":"/bc21/phone.svg"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex border-2 border-green rounded-full justify-center items-center h-7 w-7"},[_c('img',{staticClass:"h-4 filter-to-green",attrs:{"src":"/bc21/mail.svg"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex mr-2"},[_c('div',{staticClass:"flex border-green border-2 rounded-full justify-center items-center h-7 w-7"},[_c('img',{staticClass:"h-4 filter-to-green",attrs:{"src":"/bc21/placeholder.svg"}})])])}]

export { render, staticRenderFns }