<template>
  <div class="flex flex-col">
    <div
      class="flex items-center justify-start mb-4 mobile:justify-between mobile:flex-col"
    >
      <div class="flex mobile:mb-2 mobile:flex-col items-center">
        <h2 class="mr-3 mobile:mr-0 mobile:mb-2">Töötajad</h2>
        <div class="flex mobile:mb-2">
          <router-link
            to="/workers/new"
            v-if="
              $store.state.companyData.activeCompany.subscription_permissions
                .client_portal
            "
          >
            <button
              class="alt-button-green"
              v-if="
                ['R0', 'R1', 'R2'].includes(
                  $store.state.companyData.activeCompany.worker_permission
                )
              "
            >
              <span class="typcn typcn-plus icon"></span>
              <span class="label">Lisa töötaja</span>
            </button>
          </router-link>
          <button
            @click="$store.dispatch('userData/openSubWarning')"
            class="alt-button-green"
            v-if="
              !$store.state.companyData.activeCompany.subscription_permissions
                .client_portal &&
                ['R0', 'R1', 'R2'].includes(
                  $store.state.companyData.activeCompany.worker_permission
                )
            "
          >
            <span class="typcn typcn-plus icon"></span>
            <span class="label">Lisa töötaja</span>
          </button>
        </div>
      </div>
      <div
        class="ml-auto mobile:ml-0 mobile:w-full flex items-center bg-offwhite rounded-full duration-100"
        :class="focusSearch ? 'shadow-outline' : 'shadow'"
      >
        <div class="w-1/12 px-1">
          <span class="">
            <img src="/bc21/search.svg" class="h-4 w-4" />
          </span>
        </div>
        <input
          type="text"
          v-model="filterText"
          @input="debounceSearch"
          placeholder="Otsi töötajat"
          class="shadow-none focus:shadow-none hover:shadow-none"
          @focus="focusSearch = true"
          @blur="focusSearch = false"
        />
      </div>
    </div>
    <div
      class="flex flex-col mb-3"
      v-if="workersLoaded && workers.results && workers.results.length > 0"
    >
      <router-link
        v-for="worker in workers.results"
        :key="worker.id"
        :to="'/workers/' + worker.id"
        class="flex flex-col"
      >
        <company-worker-profile :worker="worker" :user-role="userRole" />
      </router-link>
      <paginator
        class="self-center"
        v-if="workers.count > 1"
        :page="page"
        :count="workers.count"
        @pageChange="pageChange"
      ></paginator>
    </div>
  </div>
</template>

<script>
import RequestHandler from "@/assets/mixins/RequestHandler";
import CompanyWorkerProfile from "@/components/company/workers/WorkerProfile";
import debounce from "lodash/debounce";
import Paginator from "@/components/reusable/Paginator";

export default {
  name: "Workers",
  components: {
    Paginator,
    CompanyWorkerProfile
  },
  mixins: [RequestHandler],
  data() {
    return {
      workers: [],
      workersLoaded: false,
      permissions: [],
      permissionManagementModalActive: false,
      canViewPermissions: true,
      activeWorker: null,
      userRole: this.$store.state.companyData.activeCompany.worker_permission,
      company: this.$store.state.companyData.activeCompany,
      owner: this.$store.state.companyData.activeCompany.owner,
      imageLoaded: false,
      ownerImage: "",
      focusSearch: false,
      filterText: "",
      page: 1
    };
  },
  methods: {
    pageChange(e) {
      this.page = e;
      this.loadCompanyWorkers();
    },
    closeModal(reload) {
      if (reload) {
        this.activeWorker = null;
        this.loadCompanyWorkers();
      } else {
        this.activeWorker = null;
      }
    },
    loadCompanyWorkers() {
      this.workersLoaded = false;
      this.apiRequest(
        "company/" +
          this.$store.state.companyData.activeCompany.uuid +
          "/workers/?page=" +
          this.page +
          (this.filterText !== "" ? "&s=" + this.filterText : ""),
        "get",
        true
      ).then(res => {
        if (res.status === 200) {
          this.workers = res.data;
          this.workersLoaded = true;
        } else if ({ res }.res.response.status === 403) {
          this.error = true;
          this.errorText = "Kasutaja aktiveerimata!";
        }
      });
    },
    addWorker() {
      this.addWorkerModalActive = true;
    },
    modalClosedHandler() {
      this.addWorkerModalActive = false;
    },
    loadImage() {
      if (Object.keys(this.owner.image).length > 0) {
        this.apiRequest(
          "files/public/" + this.owner.image.id + "/?type=ProfileImage",
          "get",
          true
        ).then(res => {
          this.ownerImage = res.data.file_url;
          this.imageLoaded = true;
        });
      } else {
        this.ownerImage = "/favicon.ico";
        this.imageLoaded = true;
      }
    },
    debounceSearch: debounce(function() {
      this.loadCompanyWorkers();
    }, 500)
  },
  watch: {
    filterText() {
      this.page = 1;
    }
  },
  mounted() {
    this.loadImage();
    this.loadCompanyWorkers();
  }
};
</script>
