var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"flex items-center justify-start mb-4 mobile:justify-between mobile:flex-col"},[_c('div',{staticClass:"flex mobile:mb-2 mobile:flex-col items-center"},[_c('h2',{staticClass:"mr-3 mobile:mr-0 mobile:mb-2"},[_vm._v("Töötajad")]),_c('div',{staticClass:"flex mobile:mb-2"},[(
            _vm.$store.state.companyData.activeCompany.subscription_permissions
              .client_portal
          )?_c('router-link',{attrs:{"to":"/workers/new"}},[(
              ['R0', 'R1', 'R2'].includes(
                _vm.$store.state.companyData.activeCompany.worker_permission
              )
            )?_c('button',{staticClass:"alt-button-green"},[_c('span',{staticClass:"typcn typcn-plus icon"}),_c('span',{staticClass:"label"},[_vm._v("Lisa töötaja")])]):_vm._e()]):_vm._e(),(
            !_vm.$store.state.companyData.activeCompany.subscription_permissions
              .client_portal &&
              ['R0', 'R1', 'R2'].includes(
                _vm.$store.state.companyData.activeCompany.worker_permission
              )
          )?_c('button',{staticClass:"alt-button-green",on:{"click":function($event){return _vm.$store.dispatch('userData/openSubWarning')}}},[_c('span',{staticClass:"typcn typcn-plus icon"}),_c('span',{staticClass:"label"},[_vm._v("Lisa töötaja")])]):_vm._e()],1)]),_c('div',{staticClass:"ml-auto mobile:ml-0 mobile:w-full flex items-center bg-offwhite rounded-full duration-100",class:_vm.focusSearch ? 'shadow-outline' : 'shadow'},[_vm._m(0),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.filterText),expression:"filterText"}],staticClass:"shadow-none focus:shadow-none hover:shadow-none",attrs:{"type":"text","placeholder":"Otsi töötajat"},domProps:{"value":(_vm.filterText)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.filterText=$event.target.value},_vm.debounceSearch],"focus":function($event){_vm.focusSearch = true},"blur":function($event){_vm.focusSearch = false}}})])]),(_vm.workersLoaded && _vm.workers.results && _vm.workers.results.length > 0)?_c('div',{staticClass:"flex flex-col mb-3"},[_vm._l((_vm.workers.results),function(worker){return _c('router-link',{key:worker.id,staticClass:"flex flex-col",attrs:{"to":'/workers/' + worker.id}},[_c('company-worker-profile',{attrs:{"worker":worker,"user-role":_vm.userRole}})],1)}),(_vm.workers.count > 1)?_c('paginator',{staticClass:"self-center",attrs:{"page":_vm.page,"count":_vm.workers.count},on:{"pageChange":_vm.pageChange}}):_vm._e()],2):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-1/12 px-1"},[_c('span',{},[_c('img',{staticClass:"h-4 w-4",attrs:{"src":"/bc21/search.svg"}})])])}]

export { render, staticRenderFns }