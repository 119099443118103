<template>
  <workers></workers>
</template>

<script>
import Workers from "@/components/company/Workers";
export default {
  components: {
    Workers
  }
};
</script>
