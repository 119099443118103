<template>
  <div
    class="w-full flex flex-col"
    :class="
      ['R0', 'R1', 'R2', 'R3'].includes(userRole) || isMe
        ? 'cursor-pointer'
        : ''
    "
  >
    <div
      class="flex w-full mobile:mx-0 flex-row px-3 py-5 h-full duration-100 mobile:flex-col"
      :class="[
        isMe
          ? 'cursor-pointer bg-green bg-opacity-5 hover:bg-opacity-25'
          : 'bg-white hover:bg-offwhite hover:bg-opacity-50'
      ]"
      @mouseover="isHovered = true"
      @mouseleave="isHovered = false"
    >
      <div class="mobile:w-full w-full flex items-center mobile:flex-col">
        <div
          class="w-3/12 mobile:w-full items-center flex flex-row mobile:mb-2"
        >
          <div class="mr-2" v-if="imageLoaded">
            <span class="typcn typcn-user table-icon" />
          </div>
          <h4 class="text-center flex items-center">
            {{ worker.profile_name }}
            <span v-if="isMe" class="text-lightgrey leading-none ml-1"
              >(mina)</span
            >
          </h4>
        </div>
        <div class="w-3/12 mobile:w-full flex items-center">
          <div
            class="flex border-2 border-green rounded-full justify-center items-center h-7 w-7"
          >
            <img src="/bc21/phone.svg" class="h-4 filter-to-green" />
          </div>
          <span class="pl-2">
            {{ worker.telephone_number ? worker.telephone_number : "-" }}</span
          >
        </div>
        <div class="w-3/12 mobile:hidden flex items-center">
          <div
            class="flex border-2 border-green rounded-full justify-center items-center h-7 w-7"
          >
            <img src="/bc21/mail.svg" class="h-4 filter-to-green" />
          </div>

          <span class="pl-2">{{ worker.email }}</span>
        </div>
        <div class="w-3/12 mobile:hidden flex items-center">
          <div class="flex mr-2">
            <div
              class="flex border-green border-2 rounded-full justify-center items-center h-7 w-7"
            >
              <img src="/bc21/placeholder.svg" class="h-4 filter-to-green" />
            </div>
          </div>
          <div class="flex truncate">
            <span
              v-if="
                ['R0', 'R1', 'R2', 'R3'].includes(userRole) ||
                  worker.profile === $store.state.userData.user.id
              "
              class="text-sm pl-2 truncate"
            >
              {{ worker.location ? worker.location.long_address : "-" }}</span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="bg-offwhite h-1 rounded-full w-full"></div>
  </div>
</template>

<script>
import RequestHandler from "@/assets/mixins/RequestHandler";

export default {
  name: "CompanyWorkerProfile",
  props: {
    worker: {
      type: Object,
      default: null
    },
    userRole: {
      type: String,
      default: ""
    }
  },
  mixins: [RequestHandler],
  data() {
    return {
      isHovered: false,
      imagePath: "",
      imageLoaded: false
    };
  },
  methods: {
    loadImage() {
      if (Object.keys(this.worker.image).length > 0) {
        this.apiRequest(
          "files/public/" + this.worker.image.id + "/?type=ProfileImage",
          "get",
          true
        ).then(res => {
          this.imagePath = res.data.file_url;
          this.imageLoaded = true;
        });
      } else {
        this.imagePath = "/favicon.ico";
        this.imageLoaded = true;
      }
    }
  },
  mounted() {
    this.loadImage();
  },
  computed: {
    profileImage() {
      if (Object.keys(this.worker.image).length === 0) {
        return "/favicon.ico";
      } else {
        return process.env.VUE_APP_MEDIA_URL + this.worker.image.image;
      }
    },
    isMe() {
      if (this.worker.profile === this.$store.state.userData.user.id) {
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>

<style lang="scss">
.provider-profile-card {
  &:hover {
    -webkit-box-shadow: 4px 6px 2px 0px rgba(35, 199, 172, 0.5);
    -moz-box-shadow: 4px 6px 2px 0px rgba(35, 199, 172, 0.5);
    box-shadow: 4px 6px 2px 0px rgba(35, 199, 172, 0.5);
  }
}
</style>
